import React, { useState } from "react"
import {
  RouterProvider,
  createRoutesFromElements,
  Route,
  createHashRouter,
} from "react-router-dom"
import About from "./pages/About"
import Home from "./pages/Home"
import styled from "styled-components"
import Admin from "./pages/Admin"
import firebase from "firebase/compat/app"
import "firebase/compat/storage"
import AdminPost from "./pages/AdminPost"
import Post from "./pages/Post"
import Case from "./pages/case"
import { getAuth, User } from "firebase/auth"
import Login from "./components/Login"
import RootLayout from "./pages/RootLayout"
import AdminLayout from "./pages/AdminLayout"
import Love from "./pages/Love"
import { getDatabase } from "firebase/database";


const firebaseConfig = {
  apiKey: 'AIzaSyCzeTHYgkLy2i7Vk9hZugizJo6AJ5dT8vs',
  authDomain: 'ghost-motion.firebaseapp.com',
  databaseURL:
    'https://ghost-motion-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'ghost-motion',
  storageBucket: 'ghost-motion.appspot.com',
  messagingSenderId: '40781524830',
  appId: '1:40781524830:web:117a0c79aa0b3c201029cf',
  measurementId: 'G-HJ53NJLTWB',
}

const StyledApp = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  &:-webkit-scrollbar {
    display: none;
  }
`

const app = firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()
export const database = getDatabase(app)

export const IsAdminContext = React.createContext(false)
export const GhostmotionAPIKeyContext = React.createContext(null)

const App: React.FC = () => {
  const [isAdmin, setIsAdmin] = useState(false)
  const [ghostmotionAPIKey, setGhostmotionAPIKey] = useState(null)
  const auth = getAuth()

  // Update the isAdmin global state whenever the user changes
  const updateIsAdmin = async (user: User | null): Promise<void> => {
    const token = await user?.getIdTokenResult()
    if (token != null) {
      if (typeof token.claims.admin === "boolean") {
        setIsAdmin(token.claims.admin)

        // If the user is an admin, get the Ghostmotion API key
        if (token.claims.admin && ghostmotionAPIKey == null) {
          const key = await db.collection("ghostmotion").doc("APIKey").get()
          setGhostmotionAPIKey(key.data()?.key)
        }
      }
    } else {
      setIsAdmin(false)
    }
  }
  auth.onAuthStateChanged((user) => {
    updateIsAdmin(user).catch((e) => alert(e))
  })

  const router = createHashRouter(
    createRoutesFromElements(
      <Route element={<RootLayout />} path='/'>
        <Route index element={<Home />} />
        <Route element={<About />} path='contact' />
        <Route element={<Case />} path='case/*' />
        <Route element={<Post />} path='post/:id' />
        <Route element={<Love />} path='love' />
        <Route element={<Login />} path='login' />
        <Route element={<AdminLayout />} path='admin'>
          <Route index element={<Admin />} />
          <Route element={<AdminPost />} path='post/:id' />
        </Route>
      </Route>
    )
  )

  return (
    <StyledApp className='App'>
      <IsAdminContext.Provider value={isAdmin}>
        <GhostmotionAPIKeyContext.Provider value={ghostmotionAPIKey}>
          <RouterProvider router={router} />
        </GhostmotionAPIKeyContext.Provider>
      </IsAdminContext.Provider>
    </StyledApp>
  )
}

export { db }

export default App
