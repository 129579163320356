import { onValue, ref } from 'firebase/database';

import { useEffect, useState } from 'react';
import { database } from '../App';

export interface UploadedImage {
  id: string
  order: number
  imageUrl: string
  timestamp: number
  height: number
  width: number
  size: number
  visible: boolean
  fileType: string
}

interface ImageData {
  url: string;
  order: number;
}

const useGetWeddingImages = () => {
  const [images, setImages] = useState<ImageData[]>([]);

  useEffect(() => {
    const databaseRef = ref(database, 'love');
    
    const unsubscribe = onValue(databaseRef, (snapshot) => {
      const imageData: ImageData[] = [];
      
      if (snapshot.exists()) {
        const data = snapshot.val();
        const keys = Object.keys(data);

        keys.forEach((key) => {
          const image = data[key] as UploadedImage;
          if (image.visible) {
            imageData.push({
              url: image.imageUrl,
              order: image.order
            });
          }
        });

        // Sort by order
        imageData.sort((a, b) => a.order - b.order);
      }
      setImages(imageData);
    }, (error) => {
      console.error('Error fetching wedding images:', error);
    });

    return () => {
      // Clean up listener
      unsubscribe();
    };
  }, []);

  return images;
};

export default useGetWeddingImages;