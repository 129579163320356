import { onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';
import { database } from '../App';

export interface WeddingPackage {
  id: string
  name: string
  description: string
  prices: {
    photographyOnly: number
    photographyAndFilm: number
  }
  variant: string
  duration: string
  included?: string[]
  optional?: string[]
}

const useGetWeddingPackages = () => {
  const [packages, setPackages] = useState<WeddingPackage[]>([]);

  useEffect(() => {
    const databaseRef = ref(database, 'services/wedding/packages');
    
    const unsubscribe = onValue(databaseRef, (snapshot) => {
      const packageData: WeddingPackage[] = [];
      
      if (snapshot.exists()) {
        const data = snapshot.val();
        const keys = Object.keys(data);

        keys.forEach((key) => {
          const pkg = data[key] as WeddingPackage;
          packageData.push({
            ...pkg,
            id: key
          });
        });
      }
      setPackages(packageData);
    }, (error) => {
      console.error('Error fetching wedding packages:', error);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return packages;
};

export default useGetWeddingPackages;